<template>
  <div
    v-if="
      isLoggedIn &&
      $route.path != '/login' &&
      !$route.path.startsWith('/register')
    "
  >
    <header class="bg-dark p-8">
      <div class="h-14 container mx-auto">
        <div class="h-14 float-left">
          <router-link to="/"
            ><img class="h-full w-auto" src="@/assets/mle-logo-light.png"
          /></router-link>
        </div>

        <span class="float-right text-white block mt-8 text-sm">
          Welcome {{ user.first_name }} {{ user.last_name }} |
          <a href="#" @click="logout" class="text-green">Logout</a>
          <span class="block text-gray-400 text-xs">{{
            user.company.name
          }}</span>
        </span>
      </div>
    </header>

    <div class="bg-green-dark">
      <div class="container mx-auto bg-green">
        <router-link
          class="inline-block px-5 py-2 text-white hover:bg-green-medium text-sm"
          :class="{ 'bg-green-medium': $route.path == '/' }"
          to="/"
          ><DesktopComputerIcon class="inline-block h-4 w-4 mb-1 mr-1" />
          Dashboard</router-link
        >
        <router-link
          class="inline-block px-5 py-2 text-white hover:bg-green-medium text-sm"
          :class="{ 'bg-green-medium': $route.path.includes('/samples') }"
          to="/samples"
          ><GiftIcon class="inline-block h-4 w-4 mb-1 mr-1" />
          Samples</router-link
        >
        <router-link
          class="inline-block px-5 py-2 text-white hover:bg-green-medium text-sm"
          :class="{ 'bg-green-medium': $route.path.includes('/quotes') }"
          to="/quotes"
          ><DocumentTextIcon class="inline-block h-4 w-4 mb-1 mr-1" />
          Quotes</router-link
        >
        <router-link
          class="inline-block px-5 py-2 text-white hover:bg-green-medium text-sm"
          :class="{ 'bg-green-medium': $route.path.includes('/commissions') }"
          to="/commissions"
        >
          <CurrencyDollarIcon class="inline-block h-4 w-4 mb-1 mr-1" />
          Commissions
        </router-link>
        <router-link
          class="inline-block px-5 py-2 text-white hover:bg-green-medium text-sm"
          :class="{
            'bg-green-medium': $route.path.includes('/parts'),
          }"
          to="/parts"
        >
          <DatabaseIcon class="inline-block h-4 w-4 mb-1 mr-1" />
          Registered Parts
        </router-link>
        <router-link
          class="inline-block px-5 py-2 text-white hover:bg-green-medium text-sm"
          :class="{ 'bg-green-medium': $route.path.includes('/trips') }"
          to="/trips"
          ><MapIcon class="inline-block h-4 w-4 mb-1 mr-1" /> Trip
          Reports</router-link
        >
      </div>
    </div>
  </div>
  <router-view />
</template>

<style lang="scss">
body,
html {
  background-color: #f0f0f0;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #222;
}

.active {
  background-color: #1e7c1e !important;
}

.bg-dark {
  background: rgb(34, 34, 34);
  background: linear-gradient(
    90deg,
    rgba(34, 34, 34, 1) 0%,
    rgba(51, 51, 51, 1) 100%
  );
}
</style>
<script>
import {
  GiftIcon,
  MapIcon,
  DocumentTextIcon,
  CurrencyDollarIcon,
  DesktopComputerIcon,
  DatabaseIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    GiftIcon,
    MapIcon,
    CurrencyDollarIcon,
    DocumentTextIcon,
    DesktopComputerIcon,
    DatabaseIcon,
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.auth.user;
    },
    user: function () {
      return this.$store.state.auth.user;
    },
  },
  created: function () {
    document.title = "MLE Rep Center";
  },
  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("login");
    },
  },
};
</script>
